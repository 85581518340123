import { fetcher } from '../fetch';

export const getFilingSummary = async (accessionNumber, url, formType) => {
  return await fetcher(
    `/api/ai/summary?accessionNumber=${accessionNumber}&url=${url}&formType=${formType}`,
    {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    }
  );
};
