import { Box, Drawer, Typography } from '@mui/material';
import styles from './Drawer.module.css';

const DrawerComponent = (props) => {
  const { open, onClose, children, anchor = 'right', title } = props;
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      classes={{ paper: styles.paper }}
      container={document.getElementById('application')}
    >
      <Box className={styles.header}>
        <Typography variant="h5" sx={{ color: 'var(--text-primary)' }}>
          {' '}
          {title}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Drawer>
  );
};

export default DrawerComponent;
